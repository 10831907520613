// src/App.js
import React, { useState, useEffect } from "react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import UpdatePrompt from './UpdatePrompt';

import Header from "./components/Header";
import ReportTable from "./components/ReportTable";
import Register from "./components/Register";
import Loader from "./components/ayuda/Loader";
import Dashboard from "./components/Dashboard";
import SyncedReportsList from "./components/SyncedReportsList";
import DailyActivitiesDashboard from "./components/DailyActivities/DailyActivitiesDashboard";
import DailyActivitiesDashboardOnline from './components/DailyActivitiesOnline/DailyActivitiesOnlineDashboard'
import { getOfflineReports, getUser, saveUsers, saveMachinery, getUsers, getMachinery } from "./idb";
import { forms } from "./components/Dashboard";

import FO_INFORME_MANTENIMIENTO_PREVENTIVO from "./components/Forms/FO_INFORME_MANTENIMIENTO - Preventivo"
import FO_INFORME_MANTENIMIENTO_CORRECTIVO from "./components/Forms/FO_INFORME_MANTENIMIENTO - Correctivo"
import ReportesOT from "./ReportesOT";

// import FO_OPE_56 from "./components/Forms/FO_OPE_56"
// import FO_OPE_57 from "./components/Forms/FO_OPE_57"
// import FO_OPE_58 from "./components/Forms/FO_OPE_58"
// import FO_OPE_59 from "./components/Forms/FO_OPE_59"
// import FO_OPE_60 from "./components/Forms/FO_OPE_60"
// import FO_OPE_61 from "./components/Forms/FO_OPE_61"
// import FO_OPE_62 from "./components/Forms/FO_OPE_62"
// import FO_OPE_63 from "./components/Forms/FO_OPE_63"
// import FO_OPE_64 from "./components/Forms/FO_OPE_64"

function App() {
  const APP_VERSION = '7.1.1'; // Debe coincidir con CACHE_NAME en service-worker.js


  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const [reports, setReports] = useState([]);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [showReportTable, setShowReportTable] = useState(false);
  const [showSyncedReports, setShowSyncedReports] = useState(false);
  const [showDailyActivities, setShowDailyActivities] = useState(false);
  const [showReportesOT, setShowReportesOT] = useState(false);
  const [showDailyActivitiesOnline, setShowDailyActivitiesOnline] = useState(false);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [usersios, setUsersios] = useState([]);
  const [maquineries, setMaquineries] = useState([]);
  const [userDni, setuserDni] = useState();

  const apiURL = "https://offline.smartaccesorios.shop/.phpOffSSOMA/api/index.php";


  useEffect(() => {
    // Verificar versión al iniciar la aplicación
    const currentVersion = localStorage.getItem('app_version');
    if (currentVersion !== APP_VERSION) {
      localStorage.setItem('app_version', APP_VERSION);
      setShowUpdatePrompt(true);
    }

    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setWaitingWorker(registration.waiting);
        setShowUpdatePrompt(true);
      },
    });
  }, []);

  useEffect(() => {
    const checkRegistration = async () => {
      const user = await getUser();
      if (user.length > 0) {
        const userDni = user[0].dni;
        setuserDni(userDni);
  
        // Fetch and update users
        try {
          const response = await fetch(`${apiURL}?route=usuarios`);
          const data = await response.json();
          await saveUsers(data);
          setUsersios(data);
          const currentUser = data.find((u) => u.dni === userDni);
          if (currentUser && currentUser.rol === "SuperAdmin") {
            setIsSuperAdmin(true);
          } else {
            if (currentUser && currentUser.rol === "Supervisor") {
              setIsSupervisor(true);
            }
          }
        } catch (error) {
          console.error("Error fetching/storing users:", error);
          // Fallback to stored data if fetch fails
          const storedUsers = await getUsers();
          setUsersios(storedUsers);
        }
  
        // Fetch and update machinery
        try {
          const response = await fetch(`${apiURL}?route=maquinarias`);
          const data = await response.json();
          await saveMachinery(data);
          setMaquineries(data.filter(machinery => machinery.estado !== "Inactivo"));
        } catch (error) {
          console.error("Error fetching/storing machinery:", error);
          // Fallback to stored data if fetch fails
          const storedMachinery = await getMachinery();
          setMaquineries(storedMachinery.filter(machinery => machinery.estado !== "Inactivo"));
        }
      }
    };
    
    checkRegistration();
  }, [apiURL]);

  const checkRegistration = async () => {
    const user = await getUser();
    setIsRegistered(user.length !== 0);
  };

  const fetchReports = async () => {
    const storedReports = await getOfflineReports();
    setReports(storedReports);
  };

  useEffect(() => {
    checkRegistration();
    fetchReports();
  }, []);

  const handleReportSaved = () => {
    fetchReports();
  };

  const renderSelectedPage = () => {
    const selectedForm = forms.find(form => form.id === selectedPage);
    const formName = selectedForm ? selectedForm.name : '';
  
    switch (selectedPage) {
      case 'FO_INFORME_MANTENIMIENTO_PREVENTIVO': return <FO_INFORME_MANTENIMIENTO_PREVENTIVO onReportSaved={handleReportSaved} title={formName} maquineries={maquineries} />;
      case 'FO_INFORME_MANTENIMIENTO_CORRECTIVO': return <FO_INFORME_MANTENIMIENTO_CORRECTIVO onReportSaved={handleReportSaved} title={formName} maquineries={maquineries} />;
      // case 'FO_OPE_56': return <FO_OPE_56 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_57': return <FO_OPE_57 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_58': return <FO_OPE_58 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_59': return <FO_OPE_59 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_60': return <FO_OPE_60 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_61': return <FO_OPE_61 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_62': return <FO_OPE_62 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_63': return <FO_OPE_63 onReportSaved={handleReportSaved} title={formName} />;
      // case 'FO_OPE_64': return <FO_OPE_64 onReportSaved={handleReportSaved} title={formName} />;
      default:
        return null;      
    }
  };

  if (loading) {
    return <Loader text="Cargando..." />;
  }

  if (!isRegistered) {
    return <Register />;
  }

  const unsyncedReportsCount = reports.filter(report => !report.synced).length;

  const handleUpdate = () => {
    const reloadPage = () => {
      window.location.reload(true); // Fuerza una recarga limpia
    };
  
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    
    setShowUpdatePrompt(false);
    
    // Espera a que el nuevo Service Worker tome el control antes de recargar
    navigator.serviceWorker.addEventListener('controllerchange', reloadPage, { once: true });
  };

  const handleCancel = () => {
    setShowUpdatePrompt(false);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="h-16"> 
        <Header />
      </div>
      
      <div className="container mx-auto p-4">
        {!selectedPage && !showReportTable && !showSyncedReports && !showDailyActivities && !showDailyActivitiesOnline && !showReportesOT ? (
          <div>
            <Dashboard 
              iSA ={isSuperAdmin}
              onSelectPage={setSelectedPage} 
              onShowReportTable={() => setShowReportTable(true)}
              onShowSyncedReports={() => setShowSyncedReports(true)}
              onShowDailyActivities={() => setShowDailyActivities(true)}
              onShowDailyActivitiesOnline={() => setShowDailyActivitiesOnline(true)}
              onShowReportesOT={() => setShowReportesOT(true)}
              reportsCount={reports.length} 
              unsyncedReportsCount={unsyncedReportsCount}
            />
          </div>
        ) : showDailyActivities ? (
          <div>
            <button
              onClick={() => {
                setShowDailyActivities(false);
                setSelectedPage(null);
              }}
              className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>← Volver al Dashboard Principal</span>
            </button>
            <DailyActivitiesDashboard usersios={usersios} maquineries={maquineries}/>
          </div>
        ) : showDailyActivitiesOnline ? (
          <div>
            <button
              onClick={() => {setSelectedPage(null); setShowDailyActivitiesOnline(false);}}
              className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>← Volver al Dashboard Principal</span>
            </button>
            <DailyActivitiesDashboardOnline usersios={usersios} maquineries={maquineries} dni={userDni} isSuperAdmin={isSuperAdmin} isSupervisor={isSupervisor}/>
          </div>
        ) : showReportTable ? (
          <div>
            <button
              onClick={() => {setShowReportTable(false); setShowSyncedReports(false);}}
              className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>← Volver al Dashboard</span>
            </button>
            <ReportTable reports={reports} loader={setLoading} />
          </div>
        ) : showSyncedReports ? (
          <div>
            <button
              onClick={() => {setShowReportTable(false); setShowSyncedReports(false);}}
              className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>← Volver al Dashboard</span>
            </button>
            <SyncedReportsList usersios={usersios} />
          </div>
        ) : showReportesOT ? (
          <div>
            <button
              onClick={() => {setShowReportesOT(false); setShowSyncedReports(false); console.log("hola")}}
              className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>← Volver al Dashboard</span>
            </button>
            <ReportesOT usersios={usersios} />
          </div>
        ) : (
          <div>
            <button
              onClick={() => setSelectedPage(null)}
              className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>← Volver al Dashboard</span>
            </button>
            <div className="bg-white rounded-lg shadow-md p-4">
              {renderSelectedPage()}
            </div>
          </div>
        )}
      </div>
      {showUpdatePrompt && (
        <UpdatePrompt onUpdate={handleUpdate} onCancel={handleCancel} />
      )}
    </div>
  );
}

export default App;
// src/components/UpdatePrompt.js
import React from 'react';

function UpdatePrompt({ onUpdate, onCancel }) {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-2xl p-6 max-w-md w-full transform transition-all hover:scale-105">
        <p className="text-lg font-semibold text-gray-800 mb-6 text-center">
          Hay una nueva versión disponible. ¿Deseas actualizar?
        </p>
        <div className="flex space-x-4 justify-center">
          <button
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-medium rounded-lg 
            hover:from-blue-600 hover:to-indigo-700 transform hover:-translate-y-0.5 transition-all duration-150 
            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={onUpdate}
          >
            Actualizar
          </button>
          <button
            className="px-6 py-2 bg-gray-100 text-gray-700 font-medium rounded-lg hover:bg-gray-200 
            transform hover:-translate-y-0.5 transition-all duration-150 
            focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            onClick={onCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdatePrompt;
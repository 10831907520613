import React, { useState, useEffect } from "react";
import ModalSyncedReports from "./components/ayuda/ModalSyncedReports";
import Loader from "./components/ayuda/Loader";
import { FiEdit } from "react-icons/fi";
// Cambiar la importación de xlsx
import { utils, writeFile } from "xlsx";

function ReportesOT({ usersios }) {
  const [syncedReports, setSyncedReports] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({ headers: [], rows: [] });

  useEffect(() => {
    fetchSyncedReports();
  }, []);

  console.log(usersios);

  const fetchSyncedReports = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://offline.smartaccesorios.shop/.phpOffSSOMA/get_synced_reports.php"
      );
      const data = await response.json();
      setSyncedReports(data);
      generateTableData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching synced reports:", error);
    }
  };

  const generateTableData = (reports) => {
    const headers = [
      "Fecha",
      "Código",
      "Equipo",
      "Descripción de Falla",
      "Opciones",
    ];
    const rows = reports.map((report) => {
      const user = usersios.find((user) => user.dni === report["sender_name"]);
      return {
        codigo: getResponseValue(report.responses, "codigo"),
        equipo: getResponseValue(report.responses, "equipo"),
        fecha: report.unique_id.split("_")[1],
        descripcion: report.form_id,
        usuario: user
          ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
          : "",
        opciones: (
          <button
            onClick={() => handleViewReport(report)}
            className="text-blue-500 hover:text-blue-700"
          >
            <FiEdit size={18} />
          </button>
        ),
      };
    });
    setTableData({ headers, rows });
  };

  const getResponseValue = (responses, key) => {
    const response = responses.find((item) => item.key === key);
    return response ? response.value : "";
  };

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const handleExportExcel = () => {
    const dataToExport = syncedReports.map((report) => {
      const user = usersios.find((user) => user.dni === report["sender_name"]);
      const responses = report.responses.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
      return {
        Usuario: user
          ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
          : "",
        Fecha: report.unique_id.split("_")[1],
        OT: responses["ot"] || "",
        Código: responses["codigo"] || "",
        Equipo: responses["equipo"] || "",
        Modelo: responses["modelo"] || "",
        NumSerie: responses["n\u00b0 de serie"] || "",
        TipoCombustible: responses["tipo de combustible"] || "",
        KilometrajeHorometro: responses["kilometraje/horometro"] || "",
        Obra: responses["obra"] || "",
        RecorridoRepuesto: responses["recorrido del repuesto"] || "",
        ReporteFalla: responses["reporte de falla"] || "",
        Causalidad: responses["causalidad"] || "",
        DescripcionFalla: responses["descripcion de falla"] || "",
        AccionCorrectiva: responses["accion correctiva"] || "",
      };
    });

    // Crear worksheet
    const worksheet = utils.json_to_sheet(dataToExport);
    
    // Definir anchos de columnas
    const columnWidths = [
      { wch: 35 }, // Usuario
      { wch: 15 }, // Fecha
      { wch: 10 }, // OT
      { wch: 15 }, // Código
      { wch: 25 }, // Equipo
      { wch: 20 }, // Modelo
      { wch: 25 }, // NumSerie
      { wch: 20 }, // TipoCombustible
      { wch: 15 }, // KilometrajeHorometro
      { wch: 20 }, // Obra
      { wch: 25 }, // RecorridoRepuesto
      { wch: 30 }, // ReporteFalla
      { wch: 30 }, // Causalidad
      { wch: 40 }, // DescripcionFalla
      { wch: 40 }, // AccionCorrectiva
    ];
    worksheet['!cols'] = columnWidths;
  
    // Añadir estilos y formato de tabla
    const range = utils.decode_range(worksheet['!ref']);
    for(let R = range.s.r; R <= range.e.r; R++) {
      for(let C = range.s.c; C <= range.e.c; C++) {
        const cell_address = utils.encode_cell({r: R, c: C});
        if(!worksheet[cell_address]) continue;
        
        // Estilo para encabezados
        if(R === 0) {
          worksheet[cell_address].s = {
            fill: { fgColor: { rgb: "4F81BD" } },
            font: { color: { rgb: "FFFFFF" }, bold: true },
            alignment: { horizontal: "center" },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" }
            }
          };
        } 
        // Estilo para el resto de celdas
        else {
          worksheet[cell_address].s = {
            font: { color: { rgb: "000000" } },
            alignment: { horizontal: "left", vertical: "center", wrapText: true },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" }
            }
          };
        }
      }
    }
  
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Reportes");
  
    // Agregar formato de tabla
    worksheet['!autofilter'] = { ref: worksheet['!ref'] };
  
    writeFile(workbook, "reportes.xlsx", { bookType: 'xlsx', bookSST: false });
  };

  if (loading) {
    return <Loader text="Cargando..." />;
  }

return (
    <div className="px-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <h2 className="text-base font-bold text-gray-800 mb-4 md:mb-0">
                Reporte Ordenes de Trabajo Correctivo - Preventivo
            </h2>
            <button
                onClick={handleExportExcel}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                Exportar a Excel
            </button>
        </div>
        <div className="relative max-h-[70vh] overflow-auto">
            <table className="w-full divide-y divide-gray-300">
                <thead className="sticky top-0 z-10">
                    <tr>
                        {tableData.headers.map((header) => (
                            <th
                                key={header}
                                className="px-6 py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {tableData.rows.map((row, idx) => (
                        <tr key={idx}>
                            <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                                {row.fecha}
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                                {row.usuario}
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                                {row.equipo}
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                                {row.descripcion}
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                                {row.opciones}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <ModalSyncedReports
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            report={selectedReport}
        />
    </div>
);
}

export default ReportesOT;
